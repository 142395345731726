import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {Container, Row, Col} from "react-bootstrap"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

class ExpiredPage extends React.Component {
  render() {
    
    return (
      <Layout>
        <SEO title="Sales Page"/>

        <Container fluid className="bg-landing-mof">
          <Container className="padding-top-bottom">
            <Row className="justify-content-center margin-top-expired pt-5 pb-5">
               <h1 className="style-font-point-one">PENAWARAN BERAKHIR</h1> 
               <Col lg={12} xs={12}>
                 <div className="image-logo">
                 <LazyLoadImage
                    alt={'EBook MoF'}
                    src={'https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/mof/MOF%20Logo.png'}
                    className="img img-fluid"
                  />
                 </div>
              </Col>
            </Row>
          </Container>
        </Container>

      </Layout>
    )
  }

}
export default ExpiredPage