/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import "./layout.css"

const Layout = ({ children }) => {

  return (
    <>
      
        <React.Fragment>
        {children}
        </React.Fragment>
         

          <nav className="navbar fixed-bottom navbar-light bg-pink ">
           <p className="text-center fu-center pt-3">
              © {new Date().getFullYear()}, Built with
              {` `}
              Jose Learning Center
            </p>
          </nav>
      
     
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
